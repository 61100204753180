












































import Vue from 'vue';
import moment from 'moment';
import { Message } from 'mycorems-app-shared';
import RouterLink from '../routing/RouterLink.vue';
import { ClientRoadmap, RoadmapItem, Task } from '../types';
import { isActive } from '../task';
import { fullDateFormat } from '../roadmap';

export default Vue.extend({
  components: {
    Message,
    RouterLink,
  },
  props: {
    clientRoadmap: {
      type: Object as () => ClientRoadmap,
    },
  },
  computed: {
    activeTasks(): Task[] {
      const { items } = this.clientRoadmap.roadmap;
      return items.reduce(
        (tasks: Task[], item: RoadmapItem) => tasks.concat(item.tasks.filter(isActive)), [],
      );
    },
    sortedTasks(): Task[] {
      return [...this.activeTasks].sort((a: Task, b: Task) => {
        if (a.startDate && b.startDate) {
          if (a.startDate < b.startDate) return -1;
          if (a.startDate > b.startDate) return 1;
          return 0;
        }
        if (!a.startDate && b.startDate) return 1;
        if (a.startDate && !b.startDate) return -1;
        if (a.lastModified < b.lastModified) return 1;
        if (a.lastModified > b.lastModified) return -1;
        return 0;
      });
    },
    visibleTasks(): Task[] {
      return this.sortedTasks.slice(0, 5);
    },
  },
  methods: {
    getItemName(roadmapItemId: string): string {
      const { items } = this.clientRoadmap.roadmap;
      const item = items.find((roadmapItem: RoadmapItem) => roadmapItem.id === roadmapItemId);
      if (!item) return '';
      return item.item.name;
    },
    formatDate(date: string|null) {
      if (!date) return 'TBD';
      return moment(date).format(fullDateFormat);
    },
  },
});
