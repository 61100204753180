import Vue from 'vue';
import { AccountType } from 'mycorems-app-accounts';
import { Account } from '../types';

interface Data {
  account: Account|null,
}

export default Vue.extend({
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      account: null,
    };
  },
  computed: {
    accountType(): number {
      return this.account ? this.account.type : -1;
    },
    isServiceProvider(): boolean {
      return AccountType.isServiceProvider(this.accountType);
    },
    isAgency(): boolean {
      return AccountType.isAgency(this.accountType);
    },
    isClient(): boolean {
      return AccountType.isClient(this.accountType);
    },
    isManager(): boolean {
      return AccountType.isManager(this.accountType);
    },
    isCustomer(): boolean {
      return AccountType.isCustomer(this.accountType);
    },
  },
});
