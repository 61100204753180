



































import Vue from 'vue';
import { DateRangePicker } from 'mycorems-app-shared';
import { NoteFilters } from '../../types';
import NotesCardDropdown from './NotesCardDropdown.vue';
import NotesTable from '../table/NotesTable.vue';
import { DateRange } from '../../../types';

interface Data {
  filters: NoteFilters,
}

export default Vue.extend({
  components: {
    DateRangePicker,
    NotesCardDropdown,
    NotesTable,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    title: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      filters: {
        accountId: this.clientId,
        keyword: undefined,
        start: undefined,
        end: undefined,
      },
    };
  },
  computed: {
    dateRange(): DateRange|null {
      if (this.filters.start && this.filters.end) {
        return {
          start: this.filters.start,
          end: this.filters.end,
        };
      }
      return null;
    },
  },
  methods: {
    selectRange(range): void {
      if (range) {
        this.filters.start = range.start;
        this.filters.end = range.end;
      } else {
        this.clearRange();
      }
    },
    clearRange(): void {
      this.filters.start = undefined;
      this.filters.end = undefined;
    },
  },
});
