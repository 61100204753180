var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    [
      _c(
        "q-toolbar",
        [
          _c("q-toolbar-title", [_vm._v("Roadmap")]),
          _vm.clientRoadmap
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: "/roadmap",
                    "account-id": _vm.clientRoadmap.client.id,
                  },
                },
                [
                  _c("q-btn", {
                    attrs: {
                      label: "View Roadmap",
                      color: "grey-8",
                      flat: "",
                      "no-caps": "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.clientRoadmap
        ? _c(
            "q-card-section",
            [
              _vm.visibleTasks.length
                ? _c(
                    "q-markup-table",
                    { attrs: { "wrap-cells": "", flat: "" } },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.visibleTasks, function (task) {
                          return _c(
                            "tr",
                            { key: task.id, staticClass: "v-top" },
                            [
                              _c(
                                "td",
                                { staticClass: "text-weight-bold text-accent" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.getItemName(task.roadmapItemId)
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "text-grey-8" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(task.description) +
                                    "\n        "
                                ),
                              ]),
                              _c("td", { staticClass: "text-grey-8" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.formatDate(task.startDate)) +
                                    " - " +
                                    _vm._s(_vm.formatDate(task.endDate)) +
                                    "\n        "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _c("message", { attrs: { type: "info" } }, [
                    _vm._v("No active roadmap tasks."),
                  ]),
            ],
            1
          )
        : _c(
            "q-card-section",
            [
              _c("message", { attrs: { type: "info" } }, [
                _vm._v("No online marketing roadmap found."),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }