import {
  Task,
  TaskAttachment,
  RoadmapItem,
} from '../types';

export function isActive(task: Task): boolean {
  return task.completedAt === null;
}

export function isComplete(task: Task): boolean {
  return task.completedAt !== null;
}

export function countTaskAttachment(tasks: Task[]): number {
  let total = 0;
  tasks.forEach((task) => {
    total += task.attachments.length;
  });
  return total;
}

export function mergeAttachments(items: RoadmapItem[]): TaskAttachment[] {
  let merged = [];
  items.forEach((item: RoadmapItem) => {
    item.tasks.forEach((task) => {
    // @ts-ignore
      merged = merged.concat(task.attachments);
    });
  });
  return merged;
}
