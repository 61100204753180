var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Confirmation" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: { task: _vm.task, label: "Delete", color: "primary" },
                on: { click: _vm.deleteTemplate },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "text-subtitle1" }, [
        _vm._v("Are you sure you want to delete this template?"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }