









import {
  Modal,
  ModalMixin,
  AsyncBtn,
  notify,
} from 'mycorems-app-shared';
import { Note } from '../../types';
import service from '../client-note-service';

interface Data {
  task: Promise<void>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
  },
  props: {
    note: {
      type: Object as () => Note,
      required: true,
    },
  },
  data(): Data {
    return {
      task: null,
    };
  },
  methods: {
    deleteNote() {
      this.task = service.delete(this.note.id);
      this.task.then(() => {
        this.$emit('ok');
        notify.success('Note deleted.');
        this.hide();
      });
    },
  },
});
