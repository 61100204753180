




import { HasTaskMixin } from 'mycorems-app-shared';
import isGranted from '@/authorization/isGranted';
import { ClientRoadmap } from '@/types';
import repository from '@/client-roadmap/client-roadmap-repository';
import ClientRoadmapCard from '@/client-roadmap/ClientRoadmapCard.vue';

interface Data {
  clientRoadmap: ClientRoadmap|null,
}

export default HasTaskMixin.extend({
  components: {
    ClientRoadmapCard,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      clientRoadmap: null,
    };
  },
  watch: {
    clientId(): void {
      this.load();
    },
  },
  methods: {
    load(): void {
      this.clientRoadmap = null;
      if (!isGranted('VIEW_ROADMAP')) return;
      this.task = repository.findByClient(this.clientId);
      this.task.then((clientRoadmap: ClientRoadmap) => {
        this.clientRoadmap = clientRoadmap;
      });
    },
  },
  mounted(): void {
    this.load();
  },
});
