var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.fields, function (field) {
        return _c("add-field-item", {
          key: field.id,
          staticClass: "q-mb-md",
          attrs: { item: field },
          on: { change: _vm.update },
        })
      }),
      _c("q-separator", { staticClass: "q-mb-md" }),
      _c("add-field-item", { on: { add: _vm.add } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }