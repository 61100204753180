var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.withInput },
    [
      _c("q-select", {
        style: _vm.canAddNew ? "width: 48%" : "",
        attrs: {
          label: _vm.label,
          value: _vm.value,
          options: _vm.tags,
          disabled: _vm.disabled,
          loading: _vm.isLoading,
          "option-label": "name",
          "option-value": "id",
          "emit-value": "",
          "map-options": "",
          multiple: "",
          outlined: "",
          clearable: _vm.canClear,
        },
        on: {
          input: function ($event) {
            return _vm.onChange($event)
          },
        },
      }),
      _vm.canAddNew
        ? _c("add-custom-tag", {
            staticStyle: { width: "48%" },
            attrs: { "account-id": _vm.accountId },
            on: { add: _vm.addTag },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }