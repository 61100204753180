































import {
  HasTaskMixin, ShowFinally, Message, formatDateTime,
} from 'mycorems-app-shared';
import { QPagination } from '../../../types';
import { Note, NoteFilters, NotesQueryResult } from '../../types';
import repository from '../client-note-repository';
import DeleteNoteBtn from '../delete/DeleteNoteBtn.vue';

interface Data {
  clientNotes: Note[],
  pagination: QPagination,
}

export default HasTaskMixin.extend({
  components: {
    ShowFinally,
    Message,
    DeleteNoteBtn,
  },
  props: {
    filters: {
      type: Object as () => NoteFilters,
      required: true,
    },
  },
  data(): Data {
    return {
      clientNotes: [],
      pagination: {
        rowsNumber: 0,
        rowsPerPage: 10,
        page: 1,
      }
    };
  },
  computed: {
    columns(): object[] {
      return [
        {
          name: 'name',
          label: 'Name',
          align: 'left',
          field: 'name',
        },
        {
          name: 'description',
          label: 'Description',
          align: 'left',
          field: 'description',
        },
        {
          name: 'meetingDate',
          label: 'Meeting Date/Time',
          align: 'left',
          field: (row: Note) => formatDateTime(row.time),
        },
        {
          name: 'actions',
          align: 'right',
        },
      ];
    },
    offset(): number {
      return ((this.pagination.page - 1) * this.pagination.rowsPerPage);
    },
    noteFilters(): NoteFilters {
      return Object.assign({}, this.filters, {
        offset: this.offset,
        limit: this.pagination.rowsPerPage,
      });
    },
  },
  watch: {
    noteFilters: {
      handler(): void {
        this.load();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    load(): void {
      this.task = repository.findAll(this.noteFilters);
      this.task.then((result: NotesQueryResult) => {
        this.pagination.rowsNumber = result.total;
        this.clientNotes = result.items;
      });
    },
    remove(noteId: string): void {
      const index = this.clientNotes.findIndex((note: Note) => note.id === noteId);
      if (index >= 0) {
        this.clientNotes.splice(index, 1);
      }
    },
  },
});
