var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Delete Note" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: { task: _vm.task, label: "Delete", color: "primary" },
                on: { click: _vm.deleteNote },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          "Are you sure you want to delete " + _vm._s(_vm.note.name) + " ?"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }