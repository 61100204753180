import adminRoutes from '../admin/routes';
import dashboardRoutes from '../dashboard/routes';

export default [
  ...adminRoutes,
  ...dashboardRoutes,
  {
    path: 'accounts/:accountId',
    component: () => import('./RouterView.vue'),
    children: [
      {
        path: '',
        component: () => import('../Home.vue'),
        props: true,
        meta: {
          title: 'Client Hub',
        },
      },
      {
        path: 'roadmap',
        component: () => import('../client-roadmap/ClientRoadmapPage.vue'),
        props: true,
        meta: {
          title: 'Roadmap',
        },
      },
      {
        path: 'attachments',
        component: () => import('../task/attachment/ClientAttachmentCard.vue'),
        props: true,
        meta: {
          title: 'Attachments',
        },
      },
      {
        path: 'notes',
        component: () => import('../note/client-note/ClientNotesPage.vue'),
        props: true,
        meta: {
          title: 'Notes',
        },
      },
      {
        path: 'notes/:noteId',
        component: () => import('../note/client-note/NoteEditor.vue'),
        props: true,
        meta: {
          title: 'Note',
        },
      },
      {
        path: 'note-templates/:templateId',
        component: () => import('../template/TemplateEditor.vue'),
        props: true,
        meta: {
          title: 'Templates',
        },
      },
    ],
  },
];
