import { Route } from 'vue-router';
import ClientDashboard from './ClientDashboard.vue';
import ClientRoadmapCard from './ClientRoadmapCard.vue';
import ClientNoteCard from '../../note/client-note/card/NotesCard.vue';
import ClientAttachmentCard from '../../task/attachment/ClientAttachmentCard.vue';

export default [
  {
    path: 'client',
    component: ClientDashboard,
    props: true,
    children: [
      {
        path: '',
        component: ClientRoadmapCard,
        props: (route: Route) => ({ clientId: route.params.accountId }),
      },
      {
        path: 'notes',
        component: ClientNoteCard,
        props: (route: Route) => ({
          clientId: route.params.accountId,
          title: true,
        }),
      },
      {
        path: 'attachments',
        component: ClientAttachmentCard,
        props: true,
      },
    ],
  },
];
