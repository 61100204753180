var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("q-input", {
        attrs: {
          "input-class": "text-bold",
          placeholder: "Add Label",
          dense: "",
          autogrow: "",
          outlined: "",
        },
        model: {
          value: _vm.field.label,
          callback: function ($$v) {
            _vm.$set(_vm.field, "label", $$v)
          },
          expression: "field.label",
        },
      }),
      !_vm.hasItem
        ? _c("q-btn", {
            staticClass: "q-mt-sm",
            attrs: { label: "Add", icon: "fa fa-plus", disable: !_vm.canAdd },
            on: { click: _vm.add },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }