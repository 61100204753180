var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientRoadmap
    ? _c("client-roadmap-card", {
        attrs: { "client-roadmap": _vm.clientRoadmap },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }