var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-table",
    _vm._b(
      {
        staticClass: "q-table-cell-vcenter no-padding",
        attrs: {
          columns: _vm.columns,
          data: _vm.templates,
          pagination: _vm.pagination,
          loading: _vm.isLoading,
          "rows-per-page-options": [],
          square: "",
          "wrap-cells": "",
        },
        on: {
          request: function ($event) {
            _vm.pagination = $event.pagination
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body-cell-name",
            fn: function (props) {
              return [
                _c(
                  "q-td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/accounts/" +
                            props.row.accountId +
                            "/note-templates/" +
                            props.row.id,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(props.row.name) + "\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "body-cell-createdAt",
            fn: function (props) {
              return [
                _c(
                  "q-td",
                  [
                    _c("humanize-time", {
                      attrs: { time: props.row.createdAt },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "body-cell-actions",
            fn: function (props) {
              return [
                _c(
                  "q-td",
                  { staticClass: "text-right" },
                  [
                    _c("save-template-btn", {
                      attrs: {
                        "account-id": _vm.accountId,
                        template: props.row,
                        icon: "fa fa-pencil-alt",
                        flat: "",
                      },
                      on: { edited: _vm.update },
                    }),
                    _c("delete-template-btn", {
                      attrs: { id: props.row.id, flat: "" },
                      on: {
                        deleted: function ($event) {
                          return _vm.remove(props.row)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "show-finally",
                  { staticClass: "full-width", attrs: { task: _vm.task } },
                  [
                    _c("message", { attrs: { type: "info" } }, [
                      _vm._v("\n        No note templates found.\n      "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "q-table",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }