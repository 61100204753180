import { AccountType } from 'mycorems-app-accounts';

const [SERVICE_PROVIDER, AGENCY] = [AccountType.SERVICE_PROVIDER, AccountType.AGENCY];

export default {
  CREATE_ROADMAP: {
    scope: 'roadmap',
    accountTypes: [SERVICE_PROVIDER, AGENCY],
  },
  CREATE_CLIENT_ROADMAP: {
    scope: 'roadmap',
    accountTypes: [SERVICE_PROVIDER, AGENCY],
  },
  VIEW_ROADMAP: {
    scope: 'roadmap:read',
  },
  EDIT_ROADMAP: {
    scope: 'roadmap',
  },
  VIEW_CLIENTS: {
    accountTypes: [SERVICE_PROVIDER, AGENCY],
  },
  CHANGE_NOTE_ACCESS: {
    accountTypes: [SERVICE_PROVIDER, AGENCY],
  },
};
