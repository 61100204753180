import { refreshAccessToken } from 'mycorems-app-accounts';
import { notify } from 'mycorems-app-shared';
import config from '../config.js';

export function refreshTokenOrLogin() {
  const task = refreshAccessToken();
  task.catch(() => {
    if (config.router) {
      config.router.push('/login?redirect=true');
    }
  });
  return task;
}

export function showError(error) {
  const excludeCodes = ['REFRESH_TOKEN.INVALID'];
  if (excludeCodes.includes(error.code)) return;
  const message = error.message || `An unexpected error occurred. Code: ${error.code}`;
  notify.failure(message);
}
