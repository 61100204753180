import { QueryResult } from '@/types';
import api from '../../API';
import { NoteFilters, Note } from '../types';

export default {
  findAll(filters: NoteFilters): Promise<QueryResult> {
    return api
      .get(`accounts/${filters.accountId}/notes`, { params: filters })
      .then((response) => {
        const total = parseInt(response.headers['x-total-items'], 10);
        return {
          total,
          items: response.data,
        };
      });
  },
  find(id: string): Promise<Note> {
    return api
      .get(`notes/${id}`)
      .then(response => response.data);
  },
};
