











import Vue from 'vue';
import ClientDrive from '@/client/ClientDrive.vue';

export default Vue.extend({
  components: {
    ClientDrive,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
});
