import api from '../API';
import { ClientRoadmap, ClientRoadmapFilters, QueryResult } from '../types';

export default {
  find(id: string): Promise<ClientRoadmap> {
    return api
      .get(`client-roadmaps/${id}`)
      .then(response => response.data);
  },
  findByClient(clientId: string): Promise<ClientRoadmap> {
    return this
      .findAll(clientId)
      .then((result) => {
        if (result.total !== 1) {
          return Promise.reject();
        }
        return (result.items as ClientRoadmap[])[0];
      });
  },
  findAll(accountId: string, filters?: ClientRoadmapFilters): Promise<QueryResult> {
    return api
      .get(`accounts/${accountId}/roadmaps`, { params: filters })
      .then((response) => {
        const total = parseInt(response.headers['x-total-items'], 10);
        return {
          total,
          items: response.data,
        };
      });
  },
};
