

















































import { HasTaskMixin, formatDateTime } from 'mycorems-app-shared';
import { Account } from '@/types';
import accountRepository from '@/account/account-repository';

interface Data {
  accounts: Account[],
}

export default HasTaskMixin.extend({
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      accounts: [],
    };
  },
  computed: {
    columns(): object[] {
      return  [
        {
          name: 'name',
          align: 'left',
          label: 'Client Name',
          field: 'name',
        },
        {
          name: 'createdAt',
          align: 'left',
          label: 'Created',
          field: row => formatDateTime(row.createdAt),
        },
        {
          name: 'actions',
          label: '',
        },
      ];
    },
  },
  watch: {
    accountId: {
      handler(): void {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load(): void {
      this.task = accountRepository.findAll(this.accountId);
      this.task.then((accounts: Account[]) => {
        this.accounts = accounts;
      });
    },
  },
});
