














import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { deleteTemplate } from './template-service';

interface Data {
  task: Promise<void>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      task: null,
    };
  },
  methods: {
    deleteTemplate(): void {
      this.task = deleteTemplate(this.id);
      this.task.then(() => {
        this.$emit('ok');
        this.hide();
      });
    },
  },
});
