var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    [
      _c("q-toolbar", [_c("q-toolbar-title", [_vm._v("Note Templates")])], 1),
      _c(
        "q-card-section",
        [
          _c("save-template-btn", {
            staticClass: "q-mb-md",
            attrs: {
              "account-id": _vm.accountId,
              label: "Create Note Template",
              icon: "fa fa-plus",
              color: "primary",
            },
            on: { created: _vm.add },
          }),
          _c("templates-table", {
            ref: "templatesTable",
            attrs: { "account-id": _vm.accountId, flat: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }