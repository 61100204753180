









import Vue from 'vue';
import { openModal } from 'mycorems-app-shared';
import CreateNoteModal from './CreateNoteModal.vue';
import { Note } from '../../types';

export default Vue.extend({
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      openModal(CreateNoteModal, {
        parent: this,
        accountId: this.accountId,
      }).onOk((note: Note) => {
        this.$emit('created', note);
      });
    },
  },
});
