















import Vue from 'vue';
import { Tag } from '../types';
import service from './tag-service';

interface Data {
  name: string,
  isLoading: boolean
}

export default Vue.extend({
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      name: '',
      isLoading: false,
    };
  },
  computed: {
    canAdd(): boolean {
      return (!this.isLoading && this.name !== '');
    },
  },
  methods: {
    add() {
      if (!this.canAdd) return;
      this.isLoading = true;
      const task = service
        .add(this.accountId, this.name)
        .then((tag: Tag) => {
          this.$emit('add', tag);
          this.name = '';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
