import Dashboard from './Dashboard.vue';
import agencyRoutes from './agency/routes';
import clientRoutes from './client/routes';

export default [
  {
    path: 'accounts/:accountId/dashboard',
    component: Dashboard,
    props: true,
    children: [
      ...agencyRoutes,
      ...clientRoutes,
    ],
  },
];
