














import { HasTaskMixin } from 'mycorems-app-shared';
import { Template, TemplateFilters } from '../types';
import repository from './template-repository';

interface Data {
  templates: Template[],
}

export default HasTaskMixin.extend({
  model: {
    event: 'change',
  },
  props: {
    filters: {
      type: Object as () => TemplateFilters,
      required: true,
    },
    label: {
      type: String,
      default: 'Select Template',
    },
    value: [String, Object as () => Template],
  },
  data(): Data {
    return {
      templates: [],
    };
  },
  watch: {
    filters: {
      handler(): void {
        this.load();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    load(): void {
      this.task = repository.findAll(this.filters);
      this.task.then((templates: Template[]) => {
        this.templates = templates;
      });
    },
  },
});
