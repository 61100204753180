import api from '../../API';
import { Tag } from '../types';

export default {
  add(
    accountId: string,
    name: string,
  ): Promise<Tag> {
    return api
      .post(`/accounts/${accountId}/tags`, { name })
      .then(res => res.data);
  },
};
