import api from '../API';
import { Account } from '../types';

export default {
  async find(id: string): Promise<Account> {
    return api
      .get(`accounts/${id}`)
      .then(response => response.data);
  },
  async findAll(parentId: string): Promise<Account[]> {
    return api
      .get(`accounts/${parentId}/accounts`)
      .then(response => response.data)
  },
};
