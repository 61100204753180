























import _ from 'lodash';
import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { Template, Field } from '../types';
import { create, updateFields } from './template-service';
import AddFields from './field/AddFields.vue';

interface Data {
  fields: Field[],
  name: string|null,
  task: Promise<Template>|Promise<Field[]>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AddFields,
    AsyncBtn,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    template: Object as () => Template,
  },
  data(): Data {
    return {
      fields: (this.template) ? this.template.fields : [],
      name: (this.template) ? this.template.name : null,
      task: null,
    };
  },
  computed: {
    title(): string {
      return (this.hasTemplate) ? 'Edit Note Template' : 'Create Note Template';
    },
    saveBtnLabel(): string {
      return (this.hasTemplate) ? 'Edit' : 'Create';
    },
    hasTemplate(): boolean {
      return (this.template !== undefined);
    },
    canSave(): boolean {
      return (
        (this.name !== null)
        && (this.fields.length > 0)
      );
    },
  },
  methods: {
    save(): void {
      if (this.hasTemplate) {
        this.edit();
      } else {
        this.create();
      }
    },
    create(): void {
      if (this.name === null) return;
      this.task = create(this.accountId, this.name, this.fields);
      this.task.then((template: Template) => {
        this.$emit('ok', { created: template });
        this.hide();
      });
    },
    edit(): void {
      if (!this.template) return;
      this.task = updateFields(this.template.id, this.fields);
      this.task.then((fields: Field[]) => {
        const template: Template = _.cloneDeep(this.template);
        template.fields = fields;
        this.$emit('ok', { edited: template });
        this.hide();
      });
    },
  },
});
