import api from '../../API';
import { Note } from '../types';
import { Field } from '../../types';

export default {
  create(
    accountId: string,
    name: string,
    description: string|null,
    time: string|null,
    tagIds: string[],
    fields: Field[]
  ): Promise<Note> {
    return api
      .post(`notes/${accountId}`, {
        name,
        description,
        time,
        tagIds,
        fields,
      })
      .then(res => res.data);
  },
  delete(id: string): Promise<void> {
    return api
      .delete(`notes/${id}`)
      .then(() => undefined);
  },
  update(
    id: string,
    name: string,
    description: string,
    time: string,
  ): Promise<void> {
    return api
      .put(`notes/${id}`, {
        name,
        description,
        time,
      }).then();
  },
  changeAccess(id: string, isExternal: boolean): Promise<void> {
    return api
      .put(`notes/${id}/change-access`, { isExternal })
      .then();
  },
};
