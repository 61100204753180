var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: _vm.title, persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: {
                  label: _vm.saveBtnLabel,
                  task: _vm.task,
                  disable: !_vm.canSave,
                  color: "primary",
                },
                on: { click: _vm.save },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "q-gutter-md" },
        [
          _c("q-input", {
            attrs: { readonly: _vm.hasTemplate, label: "Name", outlined: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("add-fields", {
            attrs: { fields: _vm.fields },
            on: {
              change: function ($event) {
                _vm.fields = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }