








import Vue from 'vue';
import { openModal } from 'mycorems-app-shared';
import { Note } from '../../types';
import DeleteNoteModal from './DeleteNoteModal.vue';

export default Vue.extend({
  props: {
    note: {
      type: Object as () => Note,
      required: true,
    },
  },
  methods: {
    openModal(): void {
      openModal(DeleteNoteModal, {
        parent: this,
        note: this.note,
      }).onOk(() => {
        this.$emit('deleted', this.note.id);
      });
    },
  },
});
