import api from '../../API';
import { Tag } from '../types';

export default {
  findAll(accountId: string): Promise<Tag[]> {
    return api
      .get(`accounts/${accountId}/tags`)
      .then(response => response.data);
  },
};
