var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    _vm._b({}, "q-card", _vm.$attrs, false),
    [
      _c("q-toolbar", [_c("q-toolbar-title", [_vm._v("Attachments")])], 1),
      _c(
        "q-card-section",
        [_c("client-drive", { attrs: { "account-id": _vm.accountId } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }