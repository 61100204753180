


















































import {
  Modal,
  ModalMixin,
  AsyncBtn,
  DatePicker,
} from 'mycorems-app-shared';
import service from '../client-note-service';
import { Note } from '../../types';
import { Field, Template, Account } from '../../../types';
import SelectTag from '../../tag/SelectTag.vue';
import AccountLoader from '../../../account/AccountLoader.vue';
import SelectTemplate from '../../../template/SelectTemplate.vue';

interface Data {
  task: Promise<Note>|null,
  name: string|null,
  description: string|null,
  time: string|null,
  tagIds: string[],
  account: Account|null,
  template: Template|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
    AccountLoader,
    SelectTemplate,
    DatePicker,
    SelectTag,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      task: null,
      name: null,
      description: null,
      time: null,
      tagIds: [],
      account: null,
      template: null,
    };
  },
  computed: {
    canCreate(): boolean {
      return (this.name !== null);
    },
    fields(): Field[] {
      return (this.template) ? this.template.fields : [];
    },
  },
  methods: {
    create(): void {
      if (!this.name) return;
      this.task = service.create(
        this.accountId,
        this.name,
        this.description,
        this.time,
        this.tagIds,
        this.fields,
      );
      this.task.then((note: Note) => {
        this.$emit('ok', note);
        this.hide();
        this.$router.push(`/roadmap/accounts/${this.accountId}/notes/${note.id}`);
      });
    },
  },
});
