var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("account-loader", {
        attrs: { "account-id": _vm.accountId },
        model: {
          value: _vm.account,
          callback: function ($$v) {
            _vm.account = $$v
          },
          expression: "account",
        },
      }),
      _c(
        "admin-layout",
        {
          attrs: { heading: "Roadmap Administration", account: _vm.account },
          scopedSlots: _vm._u([
            {
              key: "side",
              fn: function () {
                return [
                  _vm.isAgency
                    ? _c(
                        "router-link",
                        {
                          attrs: { to: "/admin", "account-id": _vm.accountId },
                        },
                        [
                          _c("nav-item", {
                            attrs: {
                              label: "Note Templates",
                              icon: "fa fa-file-alt",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }