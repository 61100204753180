var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-table", {
    staticClass: "q-table-cell-vcenter no-padding",
    attrs: {
      data: _vm.clientNotes,
      columns: _vm.columns,
      pagination: _vm.pagination,
      loading: _vm.isLoading,
      "rows-per-page-options": [],
      "wrap-cells": "",
      flat: "",
    },
    on: {
      request: function ($event) {
        _vm.pagination = $event.pagination
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body-cell-name",
        fn: function (ref) {
          var row = ref.row
          return [
            _c(
              "q-td",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to:
                        "/roadmap/accounts/" +
                        row.account.id +
                        "/notes/" +
                        row.id,
                    },
                  },
                  [_vm._v("\n        " + _vm._s(row.name) + "\n      ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "body-cell-actions",
        fn: function (ref) {
          var row = ref.row
          return [
            _c(
              "q-td",
              { staticClass: "text-right" },
              [
                _c("delete-note-btn", {
                  attrs: { note: row },
                  on: { deleted: _vm.remove },
                }),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              "show-finally",
              { staticClass: "full-width", attrs: { task: _vm.task } },
              [_c("message", [_vm._v("No client notes found.")])],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }