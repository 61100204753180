



























import Vue from 'vue';
import AddCustomTag from './AddCustomTag.vue';
import repository from './tag-repository';
import { Tag } from '../types';

interface Data {
  isLoading: Boolean,
  isAdding: Boolean,
  tags: Tag[],
}

export default Vue.extend({
  components: {
    AddCustomTag,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    value: {
      type: Array as () => String[],
      default: null,
    },
    isFullwidth: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Select Tag',
    },
    canAddNew: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      isLoading: false,
      isAdding: false,
      tags: [],
    };
  },
  computed: {
    canClear(): boolean {
      return (this.clearable);
    },
    withInput(): string {
      return this.canAddNew ? 'flex justify-between' : '';
    },
  },
  methods: {
    clear() {
      this.$emit('input', null);
    },
    load() {
      this.isLoading = true;
      const task = repository.findAll(this.accountId);
      task.then((tags: Tag[]) => {
        this.tags = tags;
      });
      task.finally(() => {
        this.isLoading = false;
      });
    },
    onChange(value: string) {
      if (value && value !== '') {
        this.$emit('input', value);
      } else {
        this.clear();
      }
    },
    addTag(tag: Tag): void {
      this.tags.push(tag);
    },
  },
  created() {
    this.load();
  },
});
