var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    { attrs: { flat: _vm.flat } },
    [
      _c(
        "q-toolbar",
        [
          _vm.title ? _c("q-toolbar-title", [_vm._v("Notes")]) : _vm._e(),
          _c("notes-card-dropdown", { attrs: { "account-id": _vm.clientId } }),
        ],
        1
      ),
      _c(
        "q-card-section",
        [
          _c("div", { staticClass: "row q-col-gutter-md q-mb-lg" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c("q-input", {
                  attrs: {
                    placeholder: "Search by note name",
                    debounce: "500",
                    "clear-icon": "fa fa-times",
                    dense: "",
                    filled: "",
                    clearable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function () {
                        return [
                          _c("q-icon", {
                            attrs: { name: "fa fa-search", size: "xs" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.filters.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "keyword", $$v)
                    },
                    expression: "filters.keyword",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c("date-range-picker", {
                  attrs: {
                    value: _vm.dateRange,
                    placeholder: "Filter by date",
                    outlined: "",
                    dense: "",
                    clearable: "",
                  },
                  on: { input: _vm.selectRange, clear: _vm.clearRange },
                }),
              ],
              1
            ),
          ]),
          _c("notes-table", { attrs: { filters: _vm.filters, flat: "" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }