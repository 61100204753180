



















import Vue from 'vue';
import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { Field } from '../../types';

interface Data {
  field: Field,
}

export default Vue.extend({
  props: {
    item: Object as () => Field,
  },
  data(): Data {
    return {
      field: (this.item) ? _.cloneDeep(this.item) : {
        id: uuidV4(),
        label: '',
      },
    };
  },
  computed: {
    hasItem(): boolean {
      return (this.item !== undefined);
    },
    canAdd(): boolean {
      return (this.field.label !== '');
    },
  },
  watch: {
    field: {
      handler(): void {
        if (!this.hasItem) return;
        this.$emit('change', this.field);
      },
      deep: true,
    },
  },
  methods: {
    add(): void {
      this.$emit('add', this.field);
      this.clear();
    },
    clear(): void {
      this.field = {
        id: uuidV4(),
        label: '',
      };
    },
  },
});
