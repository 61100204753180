import api from '../API';
import { Template, Field } from '../types';

export function create(
  accountId: string,
  name: string,
  fields: Field[],
): Promise<Template> {
  const data = {
    accountId,
    name,
    fields,
  };
  return api
    .post('templates', data)
    .then(response => response.data);
}

export function updateFields(id: string, fields: Field[]): Promise<Field[]> {
  return api
    .put(`templates/${id}/fields`, { fields })
    .then(response => response.data);
}

export function deleteTemplate(id: string): Promise<void> {
  return api
    .delete(`templates/${id}`)
    .then();
}
