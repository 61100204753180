import Admin from './Admin.vue';
import TemplatesCard from '../template/TemplatesCard.vue';

export default [
  {
    path: 'accounts/:accountId/admin',
    component: Admin,
    props: true,
    children: [
      {
        path: '',
        props: true,
        component: TemplatesCard,
      },
    ],
  },
];
