var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("drive", {
    attrs: {
      name: "accounts",
      "owner-id": _vm.accountId,
      axios: _vm.axios,
      dense: _vm.dense,
      flat: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }