




















import Vue from 'vue';
import CreateNoteBtn from '../create/CreateNoteBtn.vue';

export default Vue.extend({
  components: { CreateNoteBtn },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
});
