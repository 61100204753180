




import Vue from 'vue';
import { AxiosInstance } from 'axios';
import { Drive } from 'mycorems-app-media';
import { create } from '../API';
import { refreshTokenOrLogin, showError } from '../error';
import AxiosErrorHandler from '@/shared/AxiosErrorHandler.js';

interface Data {
  axios: AxiosInstance,
}

export default Vue.extend({
  components: { Drive },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    const axios = create(() => `${window.env.MYCOREMS_ROADMAP.API_URL}/media`);
    const handler = new AxiosErrorHandler(axios);
    handler.onAuthError(refreshTokenOrLogin);
    handler.onError(showError);
    return {
      axios,
    };
  },
});
