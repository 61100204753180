



















import Vue from 'vue';
import { Template, TemplatesTableElement } from '../types';
import SaveTemplateBtn from './SaveTemplateBtn.vue';
import TemplatesTable from './TemplatesTable.vue';

export default Vue.extend({
  components: {
    SaveTemplateBtn,
    TemplatesTable,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  methods: {
    add(template: Template): void {
      (this.$refs.templatesTable as TemplatesTableElement).add(template);
    },
  },
});
