var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-input",
    _vm._b(
      {
        attrs: {
          loading: _vm.isLoading,
          placeholder: "Add Custom Tag",
          outlined: "",
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.add($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c("q-btn", {
                  attrs: {
                    icon: "fa fa-plus",
                    disable: !_vm.canAdd,
                    dense: "",
                    flat: "",
                    round: "",
                  },
                  on: { click: _vm.add },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      },
      "q-input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }