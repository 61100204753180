export default class AxiosErrorHandler {
  constructor(axios) {
    this.axios = axios;
  }

  onAuthError(handler) {
    this.axios.interceptors.response.use(undefined, (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      return handler()
        .then(() => { // eslint-disable-line
          return this.axios.request(error.config); // Retry the request after reauthenticating
        })
        .catch(() => { // eslint-disable-line
          return Promise.reject(error);
        });
    });
  }

  onError(handler) {
    this.axios.interceptors.response.use(undefined, (error) => {
      const { response } = error;
      if (response.config.method === 'get' || response.status === 401) {
        return Promise.reject(error);
      }
      const { data } = response;
      handler({
        code: data.code,
        message: data.message,
        context: data.context,
      });
      return Promise.reject(error);
    });
  }
}
