







import Vue from 'vue';
import { AccountLoader } from 'mycorems-app-accounts';
import { Account } from '@/types';

interface Data {
  account: Account|null,
}

export default Vue.extend({
  components: {
    AccountLoader,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      account: null,
    };
  },
});
