var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Create Note" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: {
                  label: "Create",
                  color: "primary",
                  task: _vm.task,
                  disabled: !_vm.canCreate,
                },
                on: { click: _vm.create },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.create($event)
            },
          },
        },
        [
          _c(
            "account-loader",
            {
              attrs: { "account-id": _vm.accountId },
              model: {
                value: _vm.account,
                callback: function ($$v) {
                  _vm.account = $$v
                },
                expression: "account",
              },
            },
            [
              _vm.account
                ? _c(
                    "div",
                    [
                      _c("select-template", {
                        staticClass: "q-mb-sm",
                        attrs: {
                          filters: { accountId: _vm.account.parentId },
                          label: "Choose from an existing template",
                        },
                        model: {
                          value: _vm.template,
                          callback: function ($$v) {
                            _vm.template = $$v
                          },
                          expression: "template",
                        },
                      }),
                      _c("q-separator", { attrs: { spaced: "" } }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("q-input", {
            staticClass: "q-mb-sm",
            attrs: { label: "Name", outlined: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("q-input", {
            staticClass: "q-mb-sm",
            attrs: { label: "Description (Optional)", outlined: "" },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c("date-picker", {
            staticClass: "q-mb-sm",
            attrs: { placeholder: "Date/Time", time: "", outlined: "" },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
          _c("select-tag", {
            attrs: { "account-id": _vm.accountId, "can-add-new": "" },
            model: {
              value: _vm.tagIds,
              callback: function ($$v) {
                _vm.tagIds = $$v
              },
              expression: "tagIds",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }