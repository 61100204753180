

















import Vue from 'vue';
import { Account } from '@/types';
import RouterLink from '@/routing/RouterLink.vue';

export default Vue.extend({
  components: {
    AdminLayout: () => import('mycorems_app/admin/AdminLayout'),
    NavItem: () => import('mycorems_app/admin/NavItem'),
    RouterLink,
  },
  props: {
    account: {
      type: Object as () => Account,
      required: true,
    },
  },
});
