














import Vue from 'vue';
import _ from 'lodash';
import { Field } from '../../types';
import AddFieldItem from './AddFieldItem.vue';

export default Vue.extend({
  components: {
    AddFieldItem,
  },
  props: {
    fields: {
      type: Array as () => Field[],
      default(): Field[] {
        return [];
      },
    },
  },
  methods: {
    add(field: Field) {
      const fields = _.cloneDeep(this.fields);
      fields.push(field);
      this.$emit('change', fields);
    },
    update(field: Field): void {
      const fields = _.cloneDeep(this.fields);
      const index = fields.findIndex((f: Field) => f.id === field.id);
      if (index >= 0) {
        fields.splice(index, 1, field);
        this.$emit('change', fields);
      }
    },
  },
});
