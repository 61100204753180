














import AccountContextMixin from '../account/AccountContextMixin';
import AccountLoader from '../account/AccountLoader.vue';
import RouterLink from '../routing/RouterLink.vue';

export default AccountContextMixin.extend({
  components: {
    AccountLoader,
    AdminLayout: () => import('mycorems_app/admin/AdminLayout'),
    NavItem: () => import('mycorems_app/admin/NavItem'),
    RouterLink,
  },
});
