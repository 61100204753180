let config = { // eslint-disable-line
  router: null,
  URL_PREFIX: '/roadmap',
};

export default config;

export function add(con) {
  config = Object.assign(config, con);
}
