import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

// Base URL needs to be set a runtime to allow a single Docker image
// to run in different environments
function setBaseURL(request, getBaseUrl: { (): string}|null = null): AxiosRequestConfig {
  request.baseURL = (getBaseUrl !== null) ? getBaseUrl() : window.env.MYCOREMS_ROADMAP.API_URL;
  return request;
}

/* eslint-disable */
// Setting this option when creating the axios (although it's meant to)
// instance does not work so an interceptor must be used.
function sendCookies(config) {
  config.withCredentials = true;
  return config;
}
/* eslint-enable */

function fetchCreatedResource(response, httpClient: AxiosInstance) {
  if (response.status !== 201) {
    return response;
  }
  if (Object.keys(response.data).length > 0) {
    return response;
  }
  const { location } = response.headers;
  return httpClient.get(location);
}

export function create(getBaseUrl: { (): string }|null = null): AxiosInstance {
  const httpClient = axios.create();

  httpClient.interceptors.request.use(request => setBaseURL(request, getBaseUrl));
  httpClient.interceptors.request.use(sendCookies);
  httpClient.interceptors.response.use(
    response => fetchCreatedResource(response, httpClient),
  );
  return httpClient;
}

const api = create();
export default api;
