var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    [
      _c("q-toolbar", [_c("q-toolbar-title", [_vm._v("Dashboard")])], 1),
      _c(
        "q-card-section",
        [
          _c("q-table", {
            staticClass: "q-table-cell-vcenter",
            attrs: {
              data: _vm.accounts,
              columns: _vm.columns,
              "rows-per-page-options": [],
              "wrap-cells": "",
              flat: "",
            },
            scopedSlots: _vm._u([
              {
                key: "body-cell-name",
                fn: function (props) {
                  return [
                    _c(
                      "q-td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "/roadmap/accounts/" + props.row.id },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(props.row.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body-cell-actions",
                fn: function (props) {
                  return [
                    _c("q-td", [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-between" },
                        [
                          _c("q-btn", {
                            attrs: {
                              to:
                                "/roadmap/accounts/" +
                                props.row.id +
                                "/roadmap",
                              color: "blue",
                              label: "Roadmap",
                              flat: "",
                              "no-caps": "",
                            },
                          }),
                          _c("q-btn", {
                            attrs: {
                              to:
                                "/roadmap/accounts/" + props.row.id + "/notes",
                              color: "blue",
                              label: "Notes",
                              flat: "",
                              "no-caps": "",
                            },
                          }),
                          _c("q-btn", {
                            attrs: {
                              to:
                                "/roadmap/accounts/" +
                                props.row.id +
                                "/attachments",
                              color: "blue",
                              label: "Attachments",
                              flat: "",
                              "no-caps": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }