var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-btn", {
    attrs: { icon: "fa fa-trash", flat: "", round: "" },
    on: { click: _vm.openModal },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }