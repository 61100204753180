var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn-dropdown",
    {
      attrs: {
        label: "View Notes",
        to: "/roadmap/accounts/" + _vm.accountId + "/notes",
        flat: "",
        "no-caps": "",
        split: "",
      },
    },
    [
      _c(
        "q-list",
        [
          _c(
            "q-item",
            { staticClass: "no-padding" },
            [
              _c("create-note-btn", {
                staticClass: "full-width",
                attrs: { "account-id": _vm.accountId, "no-caps": "" },
                on: {
                  created: function ($event) {
                    return _vm.$emit("created", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }